$main-color: #3fe0d0;
$white: #ffffff;
$black: #222222;
$soft-grey: #dbdbdb;
$easy-grey: #dddddd;
$light-grey: #f7f7f7;
$medium-grey: #ebebeb;
$dark-grey: #c2c2c2;
$red: #943c3a;
$light-red: #e0514d;
$dark-gold: #94641c;
$light-gold: #e09c37;

$z-index-elements: immo-app, immo-map, immo-filter, immo-header, immo-modal-backdrop, immo-modal;

$media-direction: min;  // min / max — default is min (mobile first)

$breakpoints: (
  'default': '',
  'xxs': 360px,
  'xs': 480px,
  's': 667px,
  'ss': 743px,
  'm': 1024px,
  'l': 1280px,
  'll': 1440px,
  'xl': 1680px,
);

:root {
    --nav-height: 64px;
    --filter-height: 60px;
}