.info-slide-container {
    position: sticky;
    top: calc(rem-calc(32));
    .MuiPaper-root {
        box-shadow: 0px 5px 13px 7px rgba(0, 0, 0, 0.05);
    }
}

.gallery-swiper {
    .swiper-slide {
        position: relative;
        width: 100% !important;
        height: 100% !important;
    }

    img {
        object-fit: cover;
        vertical-align: bottom;
        height: 100%;
        width: 100%;
    }
    .swiper-button-next,
    .swiper-button-prev {
        height: 32px;
        width: 32px;
        background-color: #fff;
        border-radius: 50%;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        position: relative;
        font-size: 12px;
        font-weight: bold;
        color: #6c6c6c;
    }
}

.gallery-swiper.darkMode {
        .swiper-button-next,
        .swiper-button-prev {
            background-color: rgb(31, 31, 31);
        }
}

.subtitle-proplist {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row wrap;

    font: {
        weight: 600;
    }

    margin: {
        bottom: rem-calc(16);
    }

    li {
        &:not(:first-child) {
            &::before {
                content: " · ";

                margin: {
                    right: rem-calc(3);
                    left: rem-calc(8);
                }
            }
        }
    }
}